import React, { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components';
import { AccountCreationLayout } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount } from 'app/slices/account/accountSlice';
import { resendVerificationEmail, verifyAccount } from 'lib/api/account';
import { useNavigate } from 'react-router-dom';
import routes from 'lib/utils/routes';
import { addToast } from 'app/slices/toast/toastSlice';
import cuid from 'cuid';

export default function VerifyEmail() {
  const { t } = useTranslation(['verifyEmail', 'common']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accountData = useSelector(selectAccount);

  const [otpCode, setOtpCode] = useState('');
  const [error, setError] = useState('');

  const msgRequired = t('common:input.validation.required');
  const msgTooLong = t('verifyEmail:input.validation.otpCode.maxLength');
  const msgOtpCodeNumeric = t('common:input.validation.otp.numeric');

  const validateOtpCode = (code: string) => {
    if (!code.length) setError(msgRequired);
    else if (code.length > 6) setError(msgTooLong);
    else if (Boolean(code.length) && !/^[0-9]*$/.test(code)) setError(msgOtpCodeNumeric);
    else setError('');
  };

  const handleChangeOtpInput = (e: ChangeEvent<HTMLInputElement>) => {
    validateOtpCode(e.target.value);
    setOtpCode(e.target.value);
  };

  const handleSubmitOtpCode = async (confirmationCode: string, email: string) => {
    try {
      const response = await verifyAccount({ confirmationCode, email });

      if (response?.status === 200) {
        dispatch(
          addToast({
            type: 'success',
            message: t('verifyEmail:toast.verifyAccount.success'),
            id: cuid()
          })
        );
        navigate(routes.account.thankYou);
      } else {
        // TODO: 11/15/22 update error messages from API when we have them updated
        dispatch(addToast({ type: 'error', message: response?.data.error, id: cuid() }));
      }
    } catch (error) {
      dispatch(addToast({ type: 'error', message: t('common:errors.generic.server'), id: cuid() }));
    }
  };

  const handleResendVerificationCode = async (email: string) => {
    try {
      const response = await resendVerificationEmail({ email });

      if (response?.status === 200) {
        dispatch(
          addToast({
            type: 'success',
            message: t('verifyEmail:toast.resendVerificationEmail.success'),
            id: cuid()
          })
        );
      } else {
        // TODO: 11/15/22 update error messages from API when we have them updated
        dispatch(addToast({ type: 'error', message: response?.data.error, id: cuid() }));
      }
    } catch (error) {
      dispatch(addToast({ type: 'error', message: t('common:errors.generic.server'), id: cuid() }));
    }
  };

  const isVerificationCode = (code: string) => /^\d{6}$/gm.test(code);

  return (
    <AccountCreationLayout>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h1
            data-testid="verify-email-heading"
            className="text-xl md:text-2xl text-galaxy-800 text-center font-bold "
          >
            {t('verifyEmail:heading')}
          </h1>
          <p className="text-base text-galaxy-500 text-center">
            <Trans
              i18nKey={t('verifyEmail:subHeading')}
              values={{ email: accountData.email }}
              components={{
                span: <span className="font-semibold text-galaxy-800" />
              }}
            />
          </p>
        </div>
        <div className="mb-2 self-center w-52 flex flex-col items-center gap-6">
          <div className="flex flex-col gap-1">
            <p className="text-sm text-center">{t('verifyEmail:label.otpCode')}</p>
            <input
              className="h-12 w-52 text-center flex items-center text-base font-semibold text-galaxy-800 bg-white focus:bg-white border-galaxy-200 focus:border-meteor placeholder:text-galaxy-500 rounded-lg border-2 border-solid outline-none transition-colors duration-200 ease-in-out"
              id="otpCode"
              name="otpCode"
              value={otpCode}
              data-testid="otp-code-input"
              onBlur={() => validateOtpCode(otpCode)}
              onFocus={() => validateOtpCode(otpCode)}
              onChange={handleChangeOtpInput}
            />
            {Boolean(error.length) && <span className="text-sm text-mars">{error}</span>}
          </div>
          <Button
            data-testid="verify-otp-button"
            disabled={!isVerificationCode(otpCode)}
            onClick={async () => await handleSubmitOtpCode(otpCode, accountData.email)}
          >
            {t('verifyEmail:button.submit')}
          </Button>
        </div>

        <div>
          <p className="text-base text-center text-galaxy-500">
            {t('verifyEmail:requestNewCode.primary')}
          </p>
          <p
            data-testid="resend-verification-email-link"
            className="text-base text-center text-meteor underline cursor-pointer"
            onClick={async () => await handleResendVerificationCode(accountData.email)}
          >
            {t('verifyEmail:requestNewCode.secondary')}
          </p>
        </div>
      </div>
    </AccountCreationLayout>
  );
}
