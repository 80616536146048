import React from 'react';
import { TextInput, Button } from 'components';
import { useTranslation } from 'react-i18next';
import { AccountCreationLayout } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountData, selectAccount } from 'app/slices/account/accountSlice';

import { Formik, FormikProps } from 'formik';
import { createAccount } from 'lib/api/account';
import { useNavigate } from 'react-router-dom';
import routes from 'lib/utils/routes';
import * as Yup from 'yup';
import { addToast } from 'app/slices/toast/toastSlice';
import cuid from 'cuid';
import axios from 'axios';

interface InitialValues {
  postalCode: string;
  addressLineOne: string;
  companyName: string;
  numDrivers: string;
  numVehicles: string;
  state: string;
  city: string;
}

export default function CreateAccount() {
  const { t } = useTranslation(['createAccount', 'common']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const msgRequired = t('common:input.validation.required');

  const CreateAccountValidationSchema = Yup.object().shape({
    postalCode: Yup.string().required(msgRequired),
    addressLineOne: Yup.string().required(msgRequired),
    companyName: Yup.string().required(msgRequired),
    numDrivers: Yup.string().required(msgRequired),
    numVehicles: Yup.string().required(msgRequired),
    state: Yup.string().required(msgRequired),
    city: Yup.string().required(msgRequired)
  });

  const initialValues = {
    city: '',
    state: '',
    postalCode: '',
    addressLineOne: '',
    companyName: '',
    numDrivers: '',
    numVehicles: ''
  };

  const accountData = useSelector(selectAccount);

  const handleCreateAccount = async (values: InitialValues) => {
    try {
      dispatch(setAccountData(values));
      const response = await createAccount({ ...accountData, ...values });

      if (response?.data.clientID) {
        navigate(routes.account.verify);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data) {
          /* 
            TODO: API to return codified error message or code (ie error: { code: 24 } or error: { message:'client-creation-error' }) 
            This way, there is less margin for error when programmatically handling actions based on each error
            and it might also make it easier to use such a simple message to display multiple different i18n translations
            when app is translated.
            */
          dispatch(addToast({ type: 'error', message: error.response.data, id: cuid() }));
        }
      } else {
        dispatch(
          addToast({ type: 'error', message: t('common:errors.generic.server'), id: cuid() })
        );
      }
    }
  };

  return (
    <AccountCreationLayout>
      <div className="flex flex-col gap-5 w-full">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl md:text-3xl text-galaxy-800 font-bold">
            {t('createAccount:heading', {
              name: accountData.givenName
            })}
          </h1>
          <p className="self-start text-galaxy-500 font-medium text-base">
            {t('createAccount:subHeading')}
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <Formik
            initialValues={initialValues}
            validationSchema={CreateAccountValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              await handleCreateAccount(values);
              setSubmitting(false);
            }}
          >
            {(props: FormikProps<InitialValues>) => {
              const { values, errors, touched, handleChange, handleBlur, handleSubmit } = props;

              return (
                <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
                  <div className="flex flex-col md:gap-1">
                    <TextInput
                      name="companyName"
                      data-testid="companyName-input"
                      placeholder={t('createAccount:input.placeholder.companyName')}
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="text-sm text-mars" data-testid="companyName-errors">
                      {errors.companyName && touched.companyName && errors.companyName}
                    </span>
                  </div>
                  <div className="flex gap-4 md:gap-7 flex-col md:flex-row">
                    <div className="flex flex-col flex-1 md:gap-1">
                      <TextInput
                        name="numDrivers"
                        data-testid="numDrivers-input"
                        placeholder={t('createAccount:input.placeholder.numDrivers')}
                        value={values.numDrivers}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-sm text-mars" data-testid="numDrivers-errors">
                        {errors.numDrivers && touched.numDrivers && errors.numDrivers}
                      </span>
                    </div>
                    <div className="flex flex-col flex-1 md:gap-1">
                      <TextInput
                        name="numVehicles"
                        data-testid="numVehicles-input"
                        placeholder={t('createAccount:input.placeholder.numVehicles')}
                        value={values.numVehicles}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-sm text-mars" data-testid="numVehicles-errors">
                        {errors.numVehicles && touched.numVehicles && errors.numVehicles}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col md:gap-1">
                    <TextInput
                      name="addressLineOne"
                      data-testid="addressLineOne-input"
                      placeholder={t('createAccount:input.placeholder.addressLine1')}
                      value={values.addressLineOne}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="text-sm text-mars" data-testid="addressLineOne-errors">
                      {errors.addressLineOne && touched.addressLineOne && errors.addressLineOne}
                    </span>
                  </div>
                  <div className="flex gap-4 md:gap-7 flex-col md:flex-row">
                    <div className="flex flex-col flex-1 md:gap-1">
                      <TextInput
                        name="city"
                        data-testid="city-input"
                        placeholder={t('createAccount:input.placeholder.city')}
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-sm text-mars" data-testid="city-errors">
                        {errors.city && touched.city && errors.city}
                      </span>
                    </div>
                    <div className="flex flex-col flex-1 md:gap-1">
                      <TextInput
                        name="state"
                        data-testid="state-input"
                        placeholder={t('createAccount:input.placeholder.state')}
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-sm text-mars" data-testid="state-errors">
                        {errors.state && touched.state && errors.state}
                      </span>
                    </div>
                    <div className="flex flex-col flex-1 md:gap-1">
                      <TextInput
                        name="postalCode"
                        data-testid="postalCode-input"
                        placeholder={t('createAccount:input.placeholder.postalCode')}
                        value={values.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-sm text-mars" data-testid="postalCode-errors">
                        {errors.postalCode && touched.postalCode && errors.postalCode}
                      </span>
                    </div>
                  </div>
                  <Button type="submit" data-testid="finalize-account-button">
                    {t('createAccount:continue')}
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </AccountCreationLayout>
  );
}
